<template>
  <standart-template>
    <!--    <blur-component />-->
    <!--    <div class="flex">-->
    <!--      <div-->
    <!--        class="flex items-center justify-center rounded-full py-1 transition-all"-->
    <!--        @click="tab = 'notification'"-->
    <!--        :class="-->
    <!--          tab === 'notification' ? 'w-32 bg-primary' : 'w-9 bg-yellowLight'-->
    <!--        "-->
    <!--      >-->
    <!--        <div class="text-xl">-->
    <!--          <i class="fas fa-bell" />-->
    <!--        </div>-->
    <!--        <div v-show="tab === 'notification'" class="ml-3 text-sm">История</div>-->
    <!--      </div>-->
    <!--      <div-->
    <!--        class="ml-3 flex items-center justify-center whitespace-nowrap rounded-full py-1 transition-all"-->
    <!--        @click="tab = 'history'"-->
    <!--        :class="[tab === 'history' ? 'w-48 bg-primary' : 'w-9 bg-yellowLight']"-->
    <!--      >-->
    <!--        <div class="text-xl">-->
    <!--          <i class="fas fa-history" />-->
    <!--        </div>-->
    <!--        <div v-show="tab === 'history'" class="ml-3 text-sm">-->
    <!--          История выплат-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->

    <!--    v-infinite-scroll="loadMoreData"-->
    <!--    infinite-scroll-disabled="stopLoading"-->
    <!--    infinite-scroll-distance="10"-->
    <div
      v-if="platformApp === 'Android' || platformApp === 'IOS'"
      class="flex space-x-2 justify-center"
    >
      <div
        v-if="platformApp"
        class="flex justify-center text-sm text-grey text-opacity-30"
      >
        {{ platformApp }}
      </div>
      <div
        v-if="appVersion"
        class="flex justify-center text-sm text-grey text-opacity-30"
      >
        {{ appVersion }}
      </div>
    </div>
    <div class="flex space-x-2 justify-center select-none">
      <div
        class="flex justify-center text-sm text-grey text-opacity-30"
        v-if="userData && userData.phone_number"
      >
        Выплаты аккаунта: {{ userData.phone_number }}
      </div>
      <div
        v-if="platformApp === 'Pwa' || platformApp === 'Web'"
        class="flex justify-center text-sm text-grey text-opacity-30"
      >
        {{ platformApp }}
      </div>
    </div>

    <div
      v-if="isLoadData && notifications.length > 0"
      data-cy="notificationList"
      class="mt-1"
    >
      <div
        v-for="(item, index) in notifications"
        :key="index"
        class="relative mb-3 rounded-md bg-greyLight p-4"
      >
        <div class="flex items-start justify-between">
          <div class="w-[60%]">
            <div class="text-lg overflow-x-auto">
              {{ item.card_name }}
            </div>
            <div class="text-sm">
              <div>Списано: {{ item.amount }}</div>
              <div v-if="item.commission_withheld">
                Отправлено:
                {{ mathCommission(item.amount, item.commission_withheld) }}
              </div>
              <div v-if="item.commission_withheld">
                Комиссия: {{ item.commission_withheld }}
              </div>
              <div v-if="item.partner_bank_name">
                {{ `${item.partner_bank_name}` }}
              </div>
            </div>
          </div>
          <div class="flex flex-col items-center">
            <div
              :class="
                validateTransactionStatus(
                  item.status,
                  item.adapter_status,
                  'color',
                )
              "
              class="flex w-full justify-center rounded-md py-1 px-2 text-sm text-white"
            >
              {{
                validateTransactionStatus(
                  item.status,
                  item.adapter_status,
                  "text",
                )
              }}
            </div>

            <div
              v-if="
                (item.date_time && isActiveContactParent(item.date_time)) ||
                item.status != 'IN_PROCESSING'
              "
              @click="validateNotComingMoney(item)"
              class="cursor-pointer mt-3 flex w-full items-center justify-center rounded-full bg-background py-1 px-2 text-sm text-blue"
            >
              <div
                v-if="
                  item.status === 'PAID' ||
                  item.status === 'IN_PROCESSING' ||
                  item.status === 'ALLOW_TRANSACTION'
                "
              >
                Не получил
              </div>
              <div
                v-if="
                  (item.status === 'ERROR' &&
                    (item.adapter_status === 'SUCCESS' ||
                      item.adapter_status === 'NULL' ||
                      item.adapter_status === 'FAILED')) ||
                  item.status === 'BLOCK_TRANSACTION'
                "
              >
                Почему?
              </div>
              <div
                v-if="
                  item.status === 'ERROR' && item.adapter_status === 'REVERTED'
                "
              >
                Не вернулось
              </div>
            </div>
          </div>
        </div>
        <div class="text-sm mt-1">{{ item.card_number }}</div>

        <div class="absolute bottom-1 right-4 text-xs">
          {{ formatDate(item.date_time) }}
        </div>
      </div>
    </div>

    <div class="text-center text-xl" v-if="isLoadData && count <= 0">
      У вас еще нет уведомлений, но они обязательно появятся
    </div>

    <confirm-modal
      :state="helpDialogModal"
      :confirm-button-text="validateConfirmBtnText()"
      :decline-button-text="validateDeclineBtnText()"
      :htmlDescription="`${
        this.currentNotification?.status === 'ERROR' &&
        (this.currentNotification?.adapter_status === 'SUCCESS' ||
          this.currentNotification?.adapter_status === 'NULL' ||
          this.currentNotification?.adapter_status === 'FAILED')
          ? `${
              this.currentNotification?.adapter_status === 'SUCCESS'
                ? 'Деньги списались с баланса вашего кабинета.'
                : ''
            }
          ${
            this.currentNotification?.adapter_status === 'NULL' ||
            this.currentNotification?.adapter_status === 'FAILED'
              ? 'Деньги с баланса вашего кабинета <strong>НЕ СПИСАЛИСЬ</strong>.\n'
              : ''
          }  ${
              this.currentNotification?.status_description
                ? this.currentNotification?.status_description
                : ''
            }\n ${
              this.currentNotification?.status_payout ===
              'SCORE_NOT_ENOUGH_MONEY'
                ? ''
                : 'Если остались вопросы нажмите «Уточнить».\n Поддержка приложения работает с 10 до 22 по МСК.'
            } `
          : ''
      }${
        this.currentNotification?.status === 'ERROR' &&
        this.currentNotification?.adapter_status === 'REVERTED'
          ? `Деньги вернулись на баланс\n в Яндекс Про. ${
              this.currentNotification?.status_description
                ? this.currentNotification?.status_description
                : ''
            }\n Проверьте возврат средств, если возврата не произошло нажмите «Не получил» и свяжитесь с поддрежкой.\n Поддержка приложения работает с 10 до 22 по МСК. `
          : ''
      } ${
        this.currentNotification?.status === 'BLOCK_TRANSACTION'
          ? 'Транзакция на проверке в «Парке» (у партнера). Скоро статус исзменится, пожалуйста подождите.\n Если возникли вопросы нажмите «Уточнить».'
          : ''
      } ${
        this.currentNotification?.status === 'PAID' ||
        this.currentNotification?.status === 'ALLOW_TRANSACTION'
          ? 'Операция прошла успешно. Подождите минимум час и потом пришлите нам историю транзакции из приложения банка.\nЕсли транзакции там нет, дадим номер операции для поиска в вашем банке.'
          : ''
      }
       ${
         this.currentNotification?.status === 'IN_PROCESSING'
           ? 'Проведение платежа может затянуться. Пожалуйста подождите минимум час.\n Если возникли вопросы нажмите «Уточнить».'
           : ''
       }  `"
      @decline="validateDeclineBtn()"
      @confirm="validateConfirmBtn()"
    />

    <status-modal
      :state="statusModalDialog"
      button-text="Понял"
      :description="statusModalText"
      type="warning"
      @confirm="statusModalDialog = false"
    />

    <notification-page-skeleton v-if="!isLoadData" />
  </standart-template>
</template>

<script lang="ts">
import ApiNotifications from "@/api/ApiNotifications";
import ConfirmModal from "@/components/Modals/ConfirmModal.vue";
import StatusModal from "@/components/Modals/StatusModal.vue";
import NotificationPageSkeleton from "@/components/Skeleton/NotificationPageSkeleton.vue";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import { ILinkSupport } from "@/struct/ILinkSupport/ILinkSupport";
import {
  ENotificationStatus,
  ENotificationStatusAdapter,
  INotification,
} from "@/struct/INotifications/INotification";
import { IUserData } from "@/struct/IStore/IStore";
import { App } from "@capacitor/app";
import { Browser } from "@capacitor/browser";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";
import { Action, Getter } from "s-vuex-class";
import { Options, Vue } from "vue-class-component";

dayjs.extend(duration);
dayjs.extend(utc);

@Options({
  components: {
    StatusModal,
    ConfirmModal,
    NotificationPageSkeleton,
    StandartTemplate,
  },
})
export default class NotificationsPage extends Vue {
  @Action("getUserData") private getUserData: (() => void) | undefined;
  @Getter("getUserData") private userData: IUserData | undefined;
  @Action("getLinkSupport") private getLinkSupport: (() => void) | undefined;
  @Getter("getLinkSupport") private linkSupport: ILinkSupport[] | undefined;
  @Getter("validateUserPlatform") private platformApp: string | undefined;

  public waSupport!: string;
  private tab: "notification" | "history" = "notification";
  private isLoadData = false;
  private count = 0;
  private offset = 0;
  private loadNotificationInterval: number | null = null;
  private notifications: INotification[] = [];
  private currentNotification: INotification | null = null;
  private appVersion: string | null = null;

  private helpDialogModal = false;
  private disabledDialogModal = false;

  private statusModalDialog = false;
  private statusModalText = "";

  private validateDeclineBtn() {
    if (
      this.currentNotification?.status === "ERROR" &&
      this.currentNotification?.adapter_status === "REVERTED"
    ) {
      return this.waitingForPayment();
    } else {
      this.helpDialogModal = false;
    }
  }
  private validateConfirmBtn() {
    if (this.currentNotification?.status === "BLOCK_TRANSACTION") {
      return this.sendWhatsAppPark();
    } else if (
      this.currentNotification?.status === "ERROR" &&
      this.currentNotification?.adapter_status != "REVERTED"
    ) {
      if (
        this.currentNotification?.status_payout === "NO_TRANSACTION_DATA" ||
        this.currentNotification?.status_payout ===
          "DRIVER_GET_BALANCE_ERROR" ||
        this.currentNotification?.status_payout ===
          "ADAPTER_PROCESSING_ERROR" ||
        this.currentNotification?.status_payout ===
          "BANK_INITIALIZATION_ERROR" ||
        this.currentNotification?.status_payout ===
          "BANK_CREATE_PAYMENT_ERROR" ||
        this.currentNotification?.status_payout === "BANK_CONFIRMATION_ERROR" ||
        this.currentNotification?.status_payout === "TIMEOUT_CONFIRMATION_ERROR"
      ) {
        return this.sendWhatsAppSupport();
      } else {
        return this.sendWhatsAppPark();
      }
    } else if (
      this.currentNotification?.status === "ALLOW_TRANSACTION" ||
      this.currentNotification?.status === "PAID" ||
      this.currentNotification?.status === "IN_PROCESSING" ||
      (this.currentNotification?.status === "ERROR" &&
        this.currentNotification?.adapter_status === "REVERTED")
    ) {
      return this.sendWhatsAppSupport();
    } else {
      this.helpDialogModal = false;
    }
  }
  private validateConfirmBtnText() {
    if (
      this.currentNotification?.status === "ALLOW_TRANSACTION" ||
      (this.currentNotification?.status === "ERROR" &&
        this.currentNotification?.adapter_status === "REVERTED") ||
      this.currentNotification?.status === "PAID"
    ) {
      return "Не получил";
    } else if (
      this.currentNotification?.status === "BLOCK_TRANSACTION" ||
      this.currentNotification?.status === "ERROR" ||
      this.currentNotification?.status === "IN_PROCESSING"
    ) {
      return this.currentNotification?.status_payout ===
        "SCORE_NOT_ENOUGH_MONEY"
        ? "Сообщить в парк"
        : "Уточнить";
    } else {
      return "Понял";
    }
  }
  private validateDeclineBtnText() {
    if (
      this.currentNotification?.status === "ERROR" &&
      this.currentNotification?.adapter_status === "REVERTED"
    ) {
      return "Проверить";
    } else if (
      this.currentNotification?.status === "BLOCK_TRANSACTION" ||
      this.currentNotification?.status === "IN_PROCESSING"
    ) {
      return "Подождать";
    } else {
      return "Понял";
    }
  }
  private async loadData() {
    const response = await ApiNotifications.getNotifications(this.offset, 50);
    if (typeof response != "boolean") {
      if (response && response?.count && response?.results?.length > 0) {
        this.count = response.count;
        this.notifications = response.results;
      }
      this.isLoadData = true;
    }
  }

  private formatDate(date: string) {
    return dayjs(date).format("HH:mm DD.MM.YYYY");
  }

  private validateTransactionStatus(
    status: ENotificationStatus,
    adapter_status: ENotificationStatusAdapter,
    type: "color" | "text",
  ) {
    if (
      status === ENotificationStatus.Paid ||
      status === ENotificationStatus.AllowTransaction
    ) {
      return type === "color" ? "bg-green" : "Успешно";
    }

    if (status === ENotificationStatus.Process) {
      return type === "color" ? "bg-primary" : "Выполняется";
    }

    if (
      status === ENotificationStatus.Error &&
      (adapter_status === ENotificationStatusAdapter.Success ||
        adapter_status === ENotificationStatusAdapter.Null ||
        adapter_status === ENotificationStatusAdapter.Failed)
    ) {
      return type === "color" ? "bg-red" : "Ошибка";
    }
    if (
      status === ENotificationStatus.Error &&
      adapter_status === ENotificationStatusAdapter.Reverted
    ) {
      return type === "color" ? "bg-blue" : "Возвращено";
    }
    if (status === ENotificationStatus.BlockTransaction) {
      return type === "color" ? "bg-[#969DB5]" : "На проверке";
    }
  }

  private isActiveContactParent(dateString: string) {
    const date = dayjs(dateString);
    const now = dayjs();
    // Вычисляем разницу между датами
    const difference = now.diff(date);
    // Создаем длительность разницы в минутах
    const differenceInMinutes = dayjs.duration(difference).asMinutes();
    // Проверяем, прошло ли 15 минут
    return differenceInMinutes >= 15;
  }

  private validateNotComingMoney(notification: INotification) {
    if (!this.userData) this.getUserData?.();
    this.currentNotification = notification;
    this.helpDialogModal = true;
  }

  private async waitingForPayment() {
    this.helpDialogModal = false;
    this.$router.push("/home");
  }

  private async sendWhatsAppPark() {
    if (this.linkSupport) {
      this.linkSupport.forEach((el) => {
        if (
          el?.cabinet_driver_id == this.currentNotification?.cabinet_driver_id
        ) {
          this.waSupport = el.wa_support;
        }
      });
    }
    let phoneNumber = this.waSupport;

    const date = dayjs(this.currentNotification?.date_time).format(
      "DD-MM-YYYY HH:mm:ss",
    );

    let message;
    if (this.currentNotification?.status_payout === "SCORE_NOT_ENOUGH_MONEY") {
      message = encodeURI(
        `Проблема с переводом. ${this.userData?.full_name}. ${
          this.userData?.phone_number
        }. Сумма: ${this.currentNotification?.amount}, Номер карты: ${
          this.currentNotification?.card_number
        }, Дата: ${date}, Статус: ${this.validateTransactionStatus(
          this.currentNotification?.status as ENotificationStatus,
          this.currentNotification
            ?.adapter_status as ENotificationStatusAdapter,
          "text",
        )}, Причина: На счёте партнёра («Парка») было недостаточно средств.`,
      );
    } else {
      message = encodeURI(
        `Проблема с переводом. ${this.userData?.full_name}. ${
          this.userData?.phone_number
        }. Сумма: ${this.currentNotification?.amount}, Номер карты: ${
          this.currentNotification?.card_number
        }, Дата: ${date}, Статус: ${this.validateTransactionStatus(
          this.currentNotification?.status as ENotificationStatus,
          this.currentNotification
            ?.adapter_status as ENotificationStatusAdapter,
          "text",
        )}${
          this.currentNotification?.status_description
            ? `, ${this.currentNotification.status_description}`
            : ""
        }${
          this.currentNotification?.status_payout
            ? `, Причина:  ${this.currentNotification.status_payout}.`
            : ""
        }`,
      );
    }

    Browser.open({
      url: `https://wa.me/${phoneNumber}?text=${message}`,
    });
    this.helpDialogModal = false;
  }
  private async sendWhatsAppSupport() {
    let phoneNumber = encodeURI(process.env.VUE_APP_WHATSAPP_NUMBER);

    const date = dayjs(this.currentNotification?.date_time).format(
      "DD-MM-YYYY HH:mm:ss",
    );

    let message;
    if (this.currentNotification?.status_payout === "SCORE_NOT_ENOUGH_MONEY") {
      message = encodeURI(
        `Проблема с переводом. ${this.userData?.full_name}. ${
          this.userData?.phone_number
        }. Сумма: ${this.currentNotification?.amount}, Номер карты: ${
          this.currentNotification?.card_number
        }, Дата: ${date}, Статус: ${this.validateTransactionStatus(
          this.currentNotification?.status as ENotificationStatus,
          this.currentNotification
            ?.adapter_status as ENotificationStatusAdapter,
          "text",
        )}, Причина: На счёте партнёра («Парка») было недостаточно средств.`,
      );
    } else {
      message = encodeURI(
        `Проблема с переводом. ${this.userData?.full_name}. ${
          this.userData?.phone_number
        }. Сумма: ${this.currentNotification?.amount}, Номер карты: ${
          this.currentNotification?.card_number
        }, Дата: ${date}, Статус: ${this.validateTransactionStatus(
          this.currentNotification?.status as ENotificationStatus,
          this.currentNotification
            ?.adapter_status as ENotificationStatusAdapter,
          "text",
        )}${
          this.currentNotification?.status_description
            ? `, ${this.currentNotification.status_description}`
            : ""
        }${
          this.currentNotification?.status_payout
            ? `, Причина:  ${this.currentNotification.status_payout}.`
            : ""
        }`,
      );
    }

    Browser.open({
      url: `https://wa.me/${phoneNumber}?text=${message}`,
    });
    this.helpDialogModal = false;
  }

  private mathCommission(amount: number, commission: number) {
    const result = amount - commission;

    // Проверка в конце ноль или нет
    if (result % 1 === 0) {
      return result.toFixed(0);
    } else {
      return result.toFixed(1);
    }
  }

  created() {
    setTimeout(() => {
      if (!this.linkSupport) {
        this.getLinkSupport?.();
      }
      this.loadData();

      this.loadNotificationInterval = setInterval(() => {
        if (this.$route.name != "NotificationsPage") {
          clearInterval(this.loadNotificationInterval as number);
          return;
        }

        this.loadData();
      }, 15000);
    }, 2000);
    if (this.$PLATFORM != "web") {
      App.getInfo().then((appInfo) => {
        this.appVersion = `v${appInfo.version} ${appInfo.build}`;
      });
    }
  }

  unmounted() {
    clearInterval(this.loadNotificationInterval as number);
  }
}
</script>
