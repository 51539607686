<template>
  <StandartTemplate>
    <div
      v-if="loading && activeCabinet"
      class="absolute top-[.5rem] right-[.5rem] flex flex-col items-center m-4 mb-1 z-10 text-[#ec5f59] text-xs"
      @click="openWhatsappSupport()"
    >
      <div
        class="bg-[#ec5f59] border border-[#ae3d39] animate-pulse leading-3 text-xs text-white rounded-sm flex justify-center items-center text-center px-2 py-1"
      >
        Связаться<br />c «Парком»
      </div>
    </div>
    <!--      Секция с данными юзера-->
    <UserInfoComponent />

    <!--      Секция со сторис-->
    <StoryList />

    <PullRefresh
      v-model="reloadLoading"
      @refresh="onRefreshPage"
      loading-text="Обновляем информацию"
      loosing-text="Отпустите для обновления"
      pulling-text="Потяните для обновления"
      success-text="Успешно обновлено"
      success-duration="1500"
    >
      <!--      Секция с кабинетами и балансом-->
      <section>
        <div
          v-if="loading && isHaveYandexCabinets && isHaveCityCabinets"
          class="flex space-x-3 items-center text-center mt-5"
        >
          <div
            class="w-full h-[35px] border border-[#A6B0CF] rounded-sm flex items-center justify-center transition-all"
            :class="{
              'border-primary bg-primary': cabinetsServiceView == 'Yandex',
            }"
            @click="
              cabinetsServiceView = 'Yandex';
              updateActiveSlideDetails();
            "
          >
            Яндекс
          </div>
          <div
            class="w-full h-[35px] rounded-sm flex items-center justify-center transition-all border border-[#A6B0CF]"
            :class="{
              'border-primary bg-primary': cabinetsServiceView == 'CityMobil',
            }"
            @click="
              cabinetsServiceView = 'CityMobil';
              updateActiveSlideDetails();
            "
          >
            Сити
          </div>
        </div>

        <div
          class="my-4 text-xs text-gray-400 text-center cursor-pointer"
          v-show="loading && activeCabinet"
          @click="setWithdrawInfoDrawerDescription"
        >
          Посмотреть условия вывода
        </div>

        <Swiper
          v-show="loading"
          :slides-per-view="1"
          :css-mode="validateCssMode()"
          :simulate-touch="true"
          :space-between="50"
          :modules="[pagination]"
          :pagination="{ clickable: true }"
          @slideChange="updateActiveSlideDetails"
          class="relative select-none mt-3"
          id="swiper"
        >
          <template
            v-for="(item, index) in filterCabinetsDriverByService"
            :key="index"
          >
            <SwiperSlide data-cy="swiperSlide" :data-id="item.id">
              <BalanceCard
                v-model="item.balance"
                :balance="item.balance as string"
                :subtitle="'Несниж. остаток: ' + item.irreducible_balance"
                :title="item.name"
                :disabled="true"
              />
            </SwiperSlide>
          </template>
          <div
            v-if="slideArrowBounce && filterCabinetsDriverByService.length > 1"
            class="animate-swipe absolute top-14 right-5 z-20 fill-blue"
            @click.stop="infoSwiperModalDialog = true"
          >
            <svg width="38" height="38" viewBox="0 0 24 24">
              <path
                d="M3.5 4.02V2.75c0-.41-.34-.75-.75-.75S2 2.34 2 2.75V6c0 .55.45 1 1 1h3.25c.41 0 .75-.34.75-.75s-.34-.75-.75-.75H4.09c2.11-1.86 4.88-3 7.91-3c4.42 0 7.27 2.19 8.25 4.1c.12.25.38.4.66.4c.56 0 .93-.59.67-1.08C20.3 3.39 16.81 1 12 1C8.78 1 5.82 2.13 3.5 4.02zm1.7 13.41c0-.65.6-1.13 1.24-.99l3.56.8V6.5c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5v6h.91c.31 0 .62.07.89.21l4.09 2.04c.77.38 1.21 1.22 1.09 2.07l-.63 4.46c-.14.99-.99 1.72-1.98 1.72h-6.16c-.53 0-1.29-.21-1.66-.59l-4.07-4.29a.976.976 0 0 1-.28-.69z"
              />
            </svg>
          </div>
        </Swiper>

        <div v-if="loading && activeCabinet">
          <BalanceCard
            :balance="withdrawBalance"
            :subtitle="'Лимит: ' + activeCabinet.limit_amount"
            :limit="activeCabinet.limit_amount"
            title="Можно вывести"
            :max_amount="activeCabinet.amount"
            v-model="withdrawBalance"
            :isActiveCard="this.activeCabinet?.is_payment"
            @keyup="removeLetterInWithdrawBalance('withdrawBalance')"
          />

          <ButtonComponent
            height="10"
            :title="makeActiveCard"
            rounded="lg"
            class="mt-3"
            :loading="hasRequest"
            @clickAction="validateWithdrawMoney"
          />
        </div>
        <div v-else-if="retryLoading" class="mt-4">
          <div class="text-center text-xl">
            Не удалось запросить баланс с сервера. Нажмите на кнопку ниже чтобы
            запросить его
          </div>
          <ButtonComponent
            height="10"
            title="Запросить баланс"
            rounded="lg"
            class="mt-2"
            :loading="hasRequest"
            @clickAction="getCabinets(false)"
          />
        </div>

        <MainPageSkeleton v-else />

        <ConfirmModal
          v-model="confirmModalDialog"
          confirm-button-text="Да"
          :description="`Выплата начнется автоматически через ${withdrawTime} секунд.\n Вы действительно хотите вывести сумму ${withdrawBalance}\n ${formTextWithdrawConfirm()}`"
          decline-button-text="Нет"
          @decline="clearWithdrawInterval"
          @confirm="onConfirmWithdrawMoney()"
        />

        <ConfirmModal
          :description="'Списываем деньги с баланса Яндекс. Чтобы списание прошло без ошибок мы блокируем вывод на 15 минут. Нажмите ОК, чтобы перейти в раздел уведомлений и проверить статус перевода.'"
          v-model="stateInfoWithdrawDialog"
          confirm-button-text="ОК"
          @confirm="$router.push('/notifications')"
        />

        <ConfirmModal
          v-model="stateWithdrawParkInfo"
          description="Партнер не указал Whatsapp. Обратитесь к партнеру. Контакты ищите в Яндекс Про"
          confirm-button-text="Хорошо"
        />

        <ConfirmModal
          v-model="stateWithdrawInfoDrawer"
          :html-description="withdrawHtmlInfoDescription || ''"
          @openWa="openWa()"
          :description="withdrawInfoDescription"
          confirm-button-text="Хорошо"
        />

        <StatusModal
          type="success"
          description="Это свайпер, вам достаточно провести пальцем с право на лево и случится магия, попробуйте!"
          :state="infoSwiperModalDialog"
          button-text="Супер"
          @confirm="
            infoSwiperModalDialog = false;
            slideArrowBounce = false;
          "
        />

        <StatusModal
          :cards="payCardsData"
          :cardIsActivate="this.cardIsActivate"
          type="error"
          :html-description="withdrawDialogDescription"
          :state="stateWithdrawDialog"
          button-text="Хорошо"
          @confirm="validateStateWithdrawDialog()"
          @confirmCard="confirmCard()"
          @decline="openWhatsappSupport()"
          :isPaymentsOff="activeCabinet?.is_payment"
          :isActiveCard="this.isActiveCard"
        />
      </section>

      <!--      Секция с картами-->
      <section v-auto-animate>
        <div class="mt-5 mb-4 text-grey">Выберите карту для выплат</div>
        <div>
          <div v-if="payCardsData !== undefined">
            <div v-auto-animate="{ duration: 750 }">
              <PayCards
                v-for="item in payCardsData"
                :key="item.id"
                :card-id="item.id"
                :type="item.bank"
                :title="item.name"
                :subtitle="generateSubtitle(item)"
                :number-card="item.number"
                :is-active="item.is_main"
                class="mb-2"
                @click.stop="setActiveCard(item.id)"
              />
            </div>
          </div>
          <UserCards v-else />
        </div>

        <RouterLink
          to="/new-card"
          class="relative mt-3 flex h-28 items-center rounded-md bg-greyLight p-4 text-grey"
        >
          <div>Добавить карту</div>
          <NewCardIcon />
        </RouterLink>
      </section>
    </PullRefresh>
  </StandartTemplate>
</template>

<script lang="ts">
import ApiHome from "@/api/ApiHome";
import ButtonComponent from "@/components/Common/ButtonComponent.vue";
import BalanceCard from "@/components/HomePage/BalanceCard.vue";
import PayCards from "@/components/HomePage/PayCard.vue";
import ConfirmModal from "@/components/Modals/ConfirmModal.vue";
import StatusModal from "@/components/Modals/StatusModal.vue";
import MainPageSkeleton from "@/components/Skeleton/CabinetsSkeleton.vue";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import { ICabinet, ICard } from "@/struct/IHomePage/ICabinetBalance";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Options, Vue } from "vue-class-component";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ApiCard from "@/api/ApiCard";
import NewCardIcon from "@/components/Icons/NewCardIcon.vue";
import TelegramBanner from "@/components/MainPage/TelegramBanner.vue";
import UserInfoComponent from "@/components/MainPage/UserInfoComponent.vue";
import UserCards from "@/components/Skeleton/Home/UserCardsSkeleton.vue";
import StoryList from "@/components/Stories/StoryList.vue";
import store from "@/store";
import { ILinkSupport } from "@/struct/ILinkSupport/ILinkSupport";
import { IUserData } from "@/struct/IStore/IStore";
import UtilStorage from "@/util/Common/UtilStorage";
import UtilError from "@/util/ValidateError/UtilError";
import { Browser } from "@capacitor/browser";
import { Haptics, ImpactStyle } from "@capacitor/haptics";
import { Toast } from "@capacitor/toast";
import * as Sentry from "@sentry/vue";
import { Action, Getter } from "s-vuex-class";
import { toast } from "vue3-toastify";

@Options({
  components: {
    NewCardIcon,
    StoryList,
    TelegramBanner,
    UserInfoComponent,
    UserCards,
    ConfirmModal,
    StatusModal,
    MainPageSkeleton,
    ButtonComponent,
    PayCards,
    StandartTemplate,
    BalanceCard,
    Swiper,
    SwiperSlide,
    // PullRefresh,
  },
  name: "HomeView",
})
export default class HomeView extends Vue {
  @Action("fetchUserCards") private fetchUserCards: (() => void) | undefined;
  @Getter("getUserCards") private payCardsData: ICard[] | undefined;
  @Action("getUserData") private getUserData: (() => void) | undefined;
  @Getter("getUserData") private userData: IUserData | undefined;
  @Action("getLinkSupport") private getLinkSupport: (() => void) | undefined;
  @Getter("getLinkSupport") private linkSupport: ILinkSupport[] | undefined;

  private activeCabinet: ICabinet | null = null;
  private withdrawBalance = "0";
  public reloadLoading = false;
  public retryLoading = false;

  private allCabinets: ICabinet[] = [];

  public infoSwiperModalDialog = false;

  public confirmModalDialog = false;
  public slideArrowBounce = true;
  public pagination = Pagination;
  public loading = false;
  private getCabinetsInterval: number | null = null;

  public stateWithdrawParkInfo = false;
  public stateWithdrawInfoDrawer = false;
  public withdrawInfoDescription: string | string = "";
  public withdrawHtmlInfoDescription = "";
  public whatsappLink = "";
  public waSupport!: string;

  public hasRequest = false;
  private hasRequestLoadingCabinets = false;

  public stateWithdrawDialog = false;
  public withdrawDialogDescription = "";

  public stateInfoWithdrawDialog = false;
  public cabinetsServiceView: "CityMobil" | "Yandex" = "Yandex";

  public isHaveCityCabinets = false;
  private isHaveYandexCabinets = false;

  private isChangingCard = false;

  public withdrawTime = 10;
  public withdrawInterval: null | number = null;
  public cardIsActivate: boolean | null = null;
  public isActiveCard = "Вывести";

  removeLetterInWithdrawBalance(field: "withdrawBalance") {
    this[field] = this[field].replace(/[^0-9]/g, "");
  }

  get filterCabinetsDriverByService() {
    return this.allCabinets.filter(
      (item) => item.service === this.cabinetsServiceView,
    );
  }

  public async onRefreshPage() {
    if (!this.hasRequestLoadingCabinets) {
      await this.getCabinets(false);
      await this.fetchUserCards?.();
    }
    this.reloadLoading = false;
  }

  public generateSubtitle(item: ICard) {
    if (item.is_main) {
      if (!item.is_approved) {
        return "Карта не подтверждена";
      }

      return "Вывод на эту карту";
    }

    return "Нажмите, чтобы сделать активной";
  }

  get makeActiveCard() {
    if (this.payCardsData?.length === 0) {
      this.isActiveCard = "Вывести";
    } else {
      this.payCardsData?.forEach((card) => {
        if (card.is_main) {
          if (card.is_approved) {
            if (this.activeCabinet?.is_payment) {
              this.isActiveCard = "Вывести";
              return (this.cardIsActivate = true);
            } else {
              this.isActiveCard = "Включить выплаты";
              return (this.cardIsActivate = true);
            }
          } else {
            this.isActiveCard = "Подтвердить карту";
            return (this.cardIsActivate = false);
          }
        }
        return this.isActiveCard;
      });
    }
    return this.isActiveCard;
  }

  public async setActiveCard(id: string) {
    if (this.payCardsData != undefined) {
      if (this.isChangingCard) {
        if (this.$PLATFORM != "web") {
          Toast.show({ text: "Смена карты доступна не чаще раз в 10 секунд" });
        } else {
          toast("Смена карты доступна не чаще раз в 10 секунд", {
            autoClose: 3000,
            type: "warning",
          });
        }
        return;
      }

      const isAlreadyActive = this.payCardsData.find(
        (item) => item.id === id && item.is_main,
      );
      if (isAlreadyActive) {
        return;
      }
      for (const card of this.payCardsData) {
        if (card.id === id) {
          card.is_main = true;
          this.isChangingCard = true;

          await ApiCard.setMainCard(card.id);
          this.loading = false;
          await this.getCabinets(false);

          setTimeout(() => {
            this.isChangingCard = false;
          }, 10000);

          continue;
        }
        card.is_main = false;
      }
      this.payCardsData.sort((a) => {
        if (a.is_main) {
          return -1;
        }
        return 1;
      });
    }
  }

  get formattedUserFullName() {
    let userFullName = this.userData?.full_name;
    userFullName = userFullName?.split("null").join("");

    return userFullName;
  }

  public setWithdrawInfoDrawerDescription() {
    this.withdrawInfoDescription = "";
    if (this.linkSupport) {
      this.linkSupport.forEach((el) => {
        if (el?.cabinet_driver_id == this.activeCabinet?.id) {
          this.waSupport = el.wa_support;
        }
      });
    }
    const cleaned = this.waSupport?.replace(/[^\d+]/g, "");

    const formattedPhone = cleaned?.startsWith("+")
      ? cleaned.slice(1)
      : cleaned;

    const phoneNumber = encodeURI(formattedPhone);

    const userText = `Водитель ${this.formattedUserFullName} нажал на кнопку контакта с партнёром из приложения Форс Мани. Кабинет: ${this.activeCabinet?.name}. Версия приложения: ${this.$APP_TYPE}`;
    const href = encodeURI(
      `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${userText}`,
    );
    const cabinetName = `Название кабинета: ${this.activeCabinet?.name}`;
    const minAmount = `Минимум к выводу: ${this.activeCabinet?.min_amount}`;
    const dailyLimit = `Текущий суточный лимит: ${this.activeCabinet?.limit_amount}`;
    const maxAmount = `Можно за раз: ${this.activeCabinet?.max_amount}`;
    const irreducibleBalance = `Неснижаемый остаток: ${this.activeCabinet?.irreducible_balance}`;
    // const partnerWhatsapp = this.waSupport
    //   ? `Whatsapp партнера: <p class="text-blue underline">+${phoneNumber}</p>`
    //   : "Партнер не указал Whatsapp. Обратитесь к партнеру. Контакты ищите в Яндекс Про";
    this.whatsappLink = href;
    this.withdrawInfoDescription = `${cabinetName}\n${minAmount}\n${dailyLimit}\n${maxAmount}\n${irreducibleBalance}`;

    // this.withdrawHtmlInfoDescription = partnerWhatsapp;
    this.stateWithdrawInfoDrawer = true;
  }

  public openWa() {
    Browser.open({ url: this.whatsappLink });
  }

  public validateStateWithdrawDialog() {
    if (!this.payCardsData || this.payCardsData?.length <= 0) {
      this.stateWithdrawDialog = false;
      this.$router.push("/new-card");
      return;
    }

    this.stateWithdrawDialog = false;
  }

  public openWhatsappSupport() {
    if (this.linkSupport) {
      this.linkSupport.forEach((el) => {
        if (el?.cabinet_driver_id == this.activeCabinet?.id) {
          this.waSupport = el.wa_support;
        }
      });
    }
    const cleaned = this.waSupport?.replace(/[^\d+]/g, "");

    const formattedPhone = cleaned?.startsWith("+")
      ? cleaned.slice(1)
      : cleaned;

    const phoneNumber = encodeURI(formattedPhone);
    const activeCabinet = this.activeCabinet;
    const user = store.state.userData;
    const userFullName = user?.full_name.split("null").join("");
    // const waSupportNumber = this.waSupport;
    const textMessage = `Водитель ${userFullName} с номером ${user?.phone_number} нажал в приложении кнопку "У меня отключены выплаты". Кабинет ${activeCabinet?.name}`;

    const href = encodeURI(
      `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${textMessage}`,
    );
    this.waSupport
      ? Browser.open({ url: href })
      : (this.stateWithdrawParkInfo = true);
  }

  public confirmCard() {
    const currentCard = this.payCardsData?.find((item) => item.is_main);
    const user = store.state.userData;
    const userFullName = user?.full_name.split("null").join("");
    const waSupportNumber = this.waSupport;
    const textMessage = `Прошу подтвердить карту "${currentCard?.name}" ${currentCard?.number}. Водител ${userFullName}, номер телефона: ${user?.phone_number}`;

    const href = encodeURI(
      `https://api.whatsapp.com/send?phone=${waSupportNumber}&text=${textMessage}`,
    );
    Browser.open({ url: href });
  }

  public async validateWithdrawMoney() {
    this.getCards?.();
    if (this.linkSupport) {
      this.linkSupport.forEach((el) => {
        if (el?.cabinet_driver_id == this.activeCabinet?.id) {
          this.waSupport = el.wa_support;
        }
      });
    }
    const errorText: boolean | string = await UtilError.validateWithdrawError(
      this.activeCabinet,
      this.withdrawBalance,
      this.payCardsData as ICard[],
      this.waSupport,
    );

    if (typeof errorText == "string") {
      this.withdrawDialogDescription = errorText;
      this.stateWithdrawDialog = true;
      return;
    }
    this.withdrawTime = 10;

    this.withdrawInterval = setInterval(() => {
      this.withdrawTime -= 1;
      if (this.withdrawTime <= 1) {
        clearInterval(this.withdrawInterval as number);
        this.onConfirmWithdrawMoney();
      }
    }, 1000);

    this.confirmModalDialog = true;
  }

  public async updateActiveSlideDetails() {
    this.slideArrowBounce = false;

    const swiper = (document.getElementById("swiper") as any)?.swiper;

    if (swiper) {
      const id = swiper.slides[swiper.activeIndex].getAttribute("data-id");

      const cabinet = this.filterCabinetsDriverByService.find(
        (item) => item.id === id,
      );

      if (
        cabinet &&
        this.activeCabinet &&
        this.isCabinetEqual(this.activeCabinet, cabinet)
      )
        return;

      if (cabinet) {
        this.activeCabinet = cabinet;
        this.withdrawBalance = String(this.activeCabinet.amount);
      } else {
        this.activeCabinet = this.filterCabinetsDriverByService?.[0];
        this.withdrawBalance = String(this.activeCabinet.amount);
      }

      if (this.$PLATFORM != "web") {
        Haptics.impact({ style: ImpactStyle.Light });
      }
    }
  }

  public isCabinetEqual(a: ICabinet, b: ICabinet): boolean {
    if (!a || !b) {
      return false;
    }

    // Проверка, а не поменялись ди новые данные с API cabinets
    return (
      a.id === b.id &&
      a.max_amount === b.max_amount &&
      a.name === b.name &&
      a.balance === b.balance &&
      a.service === b.service &&
      a.irreducible_balance === b.irreducible_balance &&
      a.min_amount === b.min_amount &&
      // a.total_balance === b.total_balance &&
      a.limit_amount === b.limit_amount &&
      a.amount === b.amount &&
      a.is_payment === b.is_payment
    );
  }

  private async getCabinets(isInterval: boolean) {
    try {
      this.retryLoading = false;
      this.hasRequestLoadingCabinets = true;

      const response = await ApiHome.getCabinets();

      this.hasRequestLoadingCabinets = false;
      if (this.isValidCabinetsResponse(response)) {
        this.updateCabinetsData(response as ICabinet[], isInterval);
      } else {
        this.handleFailedCabinetLoading(response);
      }
    } catch (error) {
      this.retryLoading = true;
      throw "Error to get cabinets " + error;
    }
  }

  private updateCabinetsData(response: ICabinet[], isInterval: boolean) {
    this.isHaveCityCabinets = response.some(
      (item) => item.service === "CityMobil",
    );
    this.isHaveYandexCabinets = response.some(
      (item) => item.service === "Yandex",
    );

    if (!this.isHaveYandexCabinets) this.cabinetsServiceView = "CityMobil";

    this.allCabinets = response;

    if (!this.loading) {
      this.activeCabinet = response[0];
      this.withdrawBalance = String(response[0].amount);
    } else {
      this.updateActiveSlideDetails();
    }

    if (!isInterval) {
      const swiper = document.getElementById("swiper") as any;
      swiper?.swiper?.slideTo(0);
    }

    this.loading = true;
  }

  private isValidCabinetsResponse(response: any): boolean {
    return typeof response !== "boolean" && response?.length > 0;
  }

  private async handleFailedCabinetLoading(response: ICabinet[] | boolean) {
    this.loading = false;

    setTimeout(() => {
      if (this.$route.name === "Home") {
        this.retryLoading = true;
      }
    }, 1000);

    if (response && typeof response !== "boolean") {
      Sentry.captureException("Error with get cabinets", {
        extra: {
          cabinetsData: JSON.stringify(response),
          phone: await UtilStorage.getItem("phone"),
        },
      });
    }
  }

  public formTextWithdrawConfirm(): string {
    if (this.payCardsData != undefined) {
      if (this.payCardsData.length > 0) {
        const card = this.payCardsData[0];
        const limitAmount = this.activeCabinet?.limit_amount;
        const irreducibleBalance = this.activeCabinet?.irreducible_balance;

        return `на карту ${card.number} (${card.name})  \n Суточный лимит: ${limitAmount} \n Неснижаемый остаток: ${irreducibleBalance}`;
      }
    }

    return "";
  }

  public async onConfirmWithdrawMoney() {
    try {
      if (this.withdrawInterval) clearInterval(this.withdrawInterval);
      this.hasRequest = true;
      this.confirmModalDialog = false;

      const amount = Number(this.withdrawBalance);

      const response = await ApiHome.sendCreateTransaction(
        (this.activeCabinet as ICabinet).id,
        amount,
      );

      if (response && response?.detail === "REQUEST_ACCEPTED_PROCESSING") {
        if (this.$PLATFORM != "web") {
          Haptics.impact({ style: ImpactStyle.Light });
        }
        this.stateInfoWithdrawDialog = true;
        await this.getCabinets(false);
      } else if (
        response?.detail === "EXCEEDED_THE_PAYOUT_LIMIT" &&
        response?.time_left > 0
      ) {
        const minutes = Math.floor(response?.time_left / 60);
        const seconds = response?.time_left % 60;

        const message = `${response.message}\nСледующая выплата доступна через ${minutes} минут ${seconds} секунд`;
        UtilError.validateErrorMessageOnBackend(response?.detail, message);
      } else {
        UtilError.validateErrorMessageOnBackend(
          response?.detail,
          response.message,
        );
      }
    } finally {
      this.hasRequest = false;
    }
  }

  public clearWithdrawInterval() {
    clearInterval(this.withdrawInterval as number);
  }

  public validateCssMode() {
    return this.$PLATFORM != "web";
  }

  activated() {
    this.isChangingCard = false;
    this.getCabinets(false);
    this.fetchUserCards?.();
    this.getCabinetsInterval = setInterval(async () => {
      if (this.$route.name != "Home") {
        clearInterval(this.getCabinetsInterval as number);
        return;
      }
      if (!this.hasRequestLoadingCabinets) {
        await this.getCabinets(true);
      }
    }, 30000);

    if (!this.userData) {
      this.getUserData?.();
    }

    if (!this.linkSupport) {
      this.getLinkSupport?.();
    }
  }

  deactivated() {
    clearInterval(this.getCabinetsInterval as number);
    clearInterval(this.withdrawInterval as number);
  }
}
</script>
<style scoped>
@keyframes pulse {
  0% {
    box-shadow: 0 0 10px #db685f;
  }
  50% {
    box-shadow: 0 0 3px #db685f;
    opacity: 0.9;
    scale: 0.95;
  }
  100% {
    box-shadow: 0 0 10px #db685f;
  }
}
.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
</style>
