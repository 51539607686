<template>
  <Transition name="modalVersion" :duration="{ enter: 300, leave: 50 }">
    <div v-if="isOpenModalToAndroid === false">
      <div
        class="absolute top-0 left-0 w-full h-full bg-[#9CA3AF] opacity-75 z-[100]"
      ></div>
      <div
        @click="onClickOutside"
        class="absolute top-0 left-0 w-full h-full backdrop-blur-sm z-[101]"
      ></div>
      <div
        id="modalVersion"
        class="bounce-modal-animation fixed bottom-0 bg-[#ffffff] z-[102] w-full py-[35px] px-[20px] rounded-t-[24px]"
      >
        <div class="flex flex-col max-w-[400px] m-auto">
          <div class="text-2xl font-medium mb-2">
            {{ this.appVersion?.title }}
          </div>
          <div class="text-base text-[#9094A8] leading-5 mb-1">
            {{ this.appVersion?.description }}
          </div>
          <div class="text-xs text-[#9094A8] leading-4">
            Текущая версия {{ this.currentAppVersion }}
          </div>
          <div class="text-xs text-[#9094A8] leading-4">
            Доступная версия {{ this.appVersion?.current_version }}
          </div>
          <button
            class="py-5 w-full mt-[48px] text-center bg-[#ffd930] text-sm rounded-xl leading-5"
            @click="checkPlatform()"
          >
            Обновить приложение
          </button>
          <button
            v-if="this.appVersion?.detail != 'DIFFERENCE_MAJOR_VERSION'"
            @click="$emit('decline')"
            class="py-5 w-full mt-[8px] bg-[#f5f5f7] text-sm rounded-xl leading-5"
          >
            Напомнить позже
          </button>
        </div>
      </div>
    </div>
    <div v-else>
      <div
        class="absolute top-0 left-0 w-full h-full bg-[#9CA3AF] opacity-75 z-[100]"
      ></div>
      <div
        @click="onClickOutside"
        class="absolute top-0 left-0 w-full h-full backdrop-blur-sm z-[101]"
      ></div>
      <div
        id="modalVersion"
        class="bounce-modal-animation flex flex-col justify-between h-full fixed bottom-0 bg-[#ffffff] z-[102] w-full pt-[95px] pb-[35px] px-[20px]"
      >
        <button
          class="absolute top-[27px] left-[24px] w-[40px] h-[40px] rounded-full bg-[#FFF7D9]"
          @click="onToggleModalToAndroid()"
        >
          <i class="fas fa-arrow-left" />
        </button>
        <div class="text-2xl font-medium mx-auto mb-2">
          Откуда хотите установить?
        </div>
        <div class="flex flex-col max-w-[400px] w-full m-auto mb-0">
          <!-- <div
            @click="downloadFStore()"
            class="h-[113px] flex flex-col justify-center my-[auto] w-full ml-0 bg-[#ffd930] rounded-[16px] leading-5 text-left text-[16px] px-[24px] select-none"
          >
            Из FStore
            <p class="text-sm leading-3 opacity-60 mt-[4px]">
              Будет обновляться автоматически
            </p>
          </div> -->
          <!-- <div
            class="h-[113px] flex flex-col justify-center my-[auto] w-full ml-0 mt-[8px] bg-[#f5f5f7] rounded-[16px] leading-5 text-left text-[16px] px-[24px] select-none"
            @click="downloadAPK()"
          >
            С сайта (установка apk)
          </div> -->
          <div
            class="h-[113px] flex flex-col justify-center my-[auto] w-full ml-0 mt-[8px] bg-[#f5f5f7] rounded-[16px] leading-5 text-left text-[16px] px-[24px] select-none"
            @click="downloadRuStore()"
          >
            Из RuStore
          </div>
          <div
            class="h-[113px] flex flex-col justify-center my-[auto] w-full ml-0 mt-[8px] bg-[#f5f5f7] rounded-[16px] leading-5 text-left text-[16px] px-[24px] select-none"
            @click.prevent="downloadGooglePlay()"
          >
            Из Google Play
            <p class="text-xs leading-2 opacity-60 mt-[4px]">
              Если кнопка не работает, <br />
              перейдите в Google Play и введите в поиске <br />
              Force Money
            </p>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script lang="ts">
import { IAppVersion } from "@/struct/IAppVersion/IAppVersion";
import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";
import { Action, Getter } from "s-vuex-class";
import { Vue } from "vue-class-component";

export default class NewVersionModal extends Vue {
  @Action("getAppVersion") private getAppVersion: (() => void) | undefined;
  @Getter("getAppVersion") private appVersion!: IAppVersion;
  @Getter("getCurrentAppVersion") private currentAppVersion!: string;
  public isOpenModalToAndroid = false;

  public checkPlatform() {
    if (Capacitor.getPlatform() === "android") {
      this.onToggleModalToAndroid();
    } else if (Capacitor.getPlatform() === "ios") {
      Browser.open({
        url: "https://apps.apple.com/ru/app/%D1%84%D0%BE%D1%80%D1%81%D0%BC%D0%B0%D0%BD%D0%B8-pro/id1661133458",
      });
    } else {
      this.onToggleModalToAndroid();
    }
  }

  public onToggleModalToAndroid() {
    this.isOpenModalToAndroid = !this.isOpenModalToAndroid;
  }

  public downloadFStore() {
    window.open("https://fstore.force2.ru/", "_blank");
  }
  // public downloadAPK() {
  //   console.log("downloadAPK");
  // }
  public downloadRuStore() {
    window.open(
      "https://www.rustore.ru/catalog/app/money.force.driverapp",
      "_blank",
    );
  }
  public downloadGooglePlay() {
    Browser.open({
      url: "https://play.google.com/store/apps/details?id=money.force.driverapp",
    });
  }

  async created() {
    this.getAppVersion?.();
  }

  private onClickOutside() {
    const dialog = document.getElementById("modalVersion") as HTMLDivElement;
    dialog.classList.add("bounce-modal-animation");
    setTimeout(() => {
      dialog.classList.remove("bounce-modal-animation");
    }, 100);
  }
}
</script>
