<template>
  <div class="theme-light">
    <no-internet />
    <error-modal />
    <install-modal />
    <div
      class="fixed top-0 z-[49] w-full bg-background"
      :style="{ height: iosPlatformHeight }"
    />
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <keep-alive :include="['HomeView', 'LoginPage', 'TelegramBanner']">
          <component :is="Component" />
        </keep-alive>
      </transition>
      <NewVersionModal
        @decline="hideNewVersionModal()"
        v-if="
          timePut &&
          checkVersion() &&
          chechStore() &&
          (platform === 'ios' || platform === 'android')
        "
      />
    </router-view>

    <div v-if="layout" class="navigation-area z-20 bg-greyLight">
      <div
        @click="onClickTab(tab.route)"
        class="tab"
        :style="
          isNeedBottomPadding()
            ? `padding-bottom: ${navigationBottomPadding}px`
            : ''
        "
        v-for="tab in navigationTabs"
        :key="tab.icon"
        :class="{ 'tab-active': tab.route === currentRoute }"
      >
        <div
          class="tab-icon text-darkGrey"
          :class="{ 'text-greyLight': tab.route === currentRoute }"
        >
          <i :class="tab.icon" />
        </div>
        <div class="text-[10px] text-grey">
          {{ tab.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

import ErrorModal from "@/components/Modals/ErrorModal.vue";
import InstallModal from "@/components/Modals/InstallModal.vue";
import NewVersionModal from "@/components/Modals/NewVersionModal.vue";
import NoInternet from "@/components/Statuses/NoInternet.vue";
import { IAppVersion } from "@/struct/IAppVersion/IAppVersion";
import UtilStorage from "@/util/Common/UtilStorage";
import { Capacitor } from "@capacitor/core";
import { SafeArea } from "capacitor-plugin-safe-area";
import { Action, Getter, Mutation } from "s-vuex-class";
import store from "./store";

@Options({
  components: { InstallModal, NoInternet, ErrorModal, NewVersionModal },
})
export default class App extends Vue {
  @Mutation("setDeferredPrompt") private setDeferredPrompt:
    | ((value: any) => void)
    | undefined;
  @Action("getAppVersion") private getAppVersion: (() => void) | undefined;
  @Getter("getAppVersion") private appVersion!: IAppVersion;
  private currentAppVersion!: string;
  public platform = Capacitor.getPlatform();
  public checkVersion() {
    if (this.appVersion?.detail != "VERSION_SUCCESSES") {
      store.state.isOpenNewVersionModal === true;
      document.body.style.overflow = "hidden";
      return true;
    } else {
      document.body.style.overflow = "visible";
      return false;
    }
  }
  public timePut = false;

  public chechStore() {
    if (store.state.isOpenNewVersionModal === true) {
      document.body.style.overflow = "hidden";
      return true;
    } else {
      document.body.style.overflow = "visible";
      return false;
    }
  }

  public hideNewVersionModal() {
    store.state.isOpenNewVersionModal = false;
    this.chechStore();
  }

  public navigationBottomPadding = 10;
  private iosPlatformHeight: string | null = null;
  private navigationTabs: { icon: string; route: string; title: string }[] = [
    {
      icon: "fas fa-home",
      route: "/home",
      title: "Главная",
    },
    {
      icon: "fas fa-bell",
      route: "/notifications",
      title: "Уведомления",
    },
    {
      icon: "fas fa-user",
      route: "/profile",
      title: "Профиль",
    },
    {
      icon: "fas fa-cog",
      route: "/settings",
      title: "Настройки",
    },
  ];

  get layout() {
    return this.$route.meta.layout || false;
  }

  get currentRoute() {
    return this.$route.path;
  }

  private onClickTab(link: string) {
    this.$router.replace(link);
  }

  private isNeedBottomPadding() {
    if (this.$PLATFORM === "ios") {
      SafeArea.getSafeAreaInsets().then(({ insets }) => {
        this.iosPlatformHeight = insets.top + "px";
      });
      return false;
    }

    if (this.$IS_PWA && this.$IS_SAFARI) {
      return false;
    }

    return true;
  }

  created() {
    if (this.$PLATFORM == "web") {
      window.addEventListener("beforeinstallprompt", (event) => {
        // Prevent the default behavior of the event
        event.preventDefault();
        // Save the event for later
        this.setDeferredPrompt?.(event);
        UtilStorage.removeItem("appInstalled");
      });
    }
    this.getAppVersion?.();
    setTimeout(() => {
      this.timePut = true;
    }, 2000);
  }
}
</script>
